import React, { useEffect } from "react";
import classes from "./About.module.css";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.about}>
      <h1>Bemutatkozás</h1>
      <div className={classes.about_info}>
        <p>
          Iskolánk és ezzel együtt oktatóink célja, hogy a gazdik mégjobban
          megismerjék kutyáikat és kihozhassák belőlük a legtöbbet, teljes
          összhangban tudjanak élni a mindennapokban. Szeretnénk számos kutyás
          foglalkozást bemutatni és lehetőséget teremteni arra, hogy mindenki
          megtalálja a számukra legjobb sportot és kicsit kinyíljon a kutyás
          világ a kisvárosokban, köztük itt, Albertirsán is.
        </p>
      </div>
    </div>
  );
};

export default About;
