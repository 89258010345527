import CourseGridElement from "./CourseGridElement";
import classes from "./CourseGrid.module.css";
import oviPic from "../../assets/ovi.jpg";
import szimat from "../../assets/szimat.jpg";
import olvas from "../../assets/olvas.jpg";
import stay from "../../assets/stay.jpg";
import piti from "../../assets/piti.jpg";
import orzo from "../../assets/orzo.png";
import solo from "../../assets/solo.jpg";
import obed from "../../assets/obed.png";
import hoopers from "../../assets/hoopers.jpg";
import { useEffect } from "react";
import React from "react";

const CourseGrid = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={classes.courseGrid}>
      <CourseGridElement title="Alapfok" photoSrc={stay} />
      <CourseGridElement title="Középfok" photoSrc={piti} />
      <CourseGridElement title="Szimat Suli" photoSrc={szimat} />
      <CourseGridElement title="Kölyök tréning" photoSrc={oviPic} />
      <CourseGridElement title="Rally Obedience" photoSrc={obed} />
      <CourseGridElement title="Városiasodás" photoSrc={orzo} />
      <CourseGridElement title="Hoopers" photoSrc={hoopers} />
      <CourseGridElement title="Egyéni képzés" photoSrc={solo} />
      <CourseGridElement title="Egyéb szolgáltatásaink" photoSrc={olvas} />
    </main>
  );
};

export default CourseGrid;
