import classes from "./Footer.module.css";
import instagram from "../../assets/instagram.png";
import facebook from "../../assets/facebook.png";
import marker from "../../assets/marker.png";
import hazirend from "../../assets/hazirend.docx";
import React from "react";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.footer_left}>
        <p>Tippek, praktikák?</p>
        <p>
          Légy mindig naprakész és kövess minket <br /> Facebookon vagy
          Instagramon
        </p>
        <div>
          <a
            href="https://www.instagram.com/4dogs_kutyaiskola/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagram}
              className={classes.insta}
              alt="instagram logo icon - click to reach fordogs instagram page"
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100092629218461"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={facebook}
              alt="facebook logo icon - click to reach fordogs facebook page"
            />
          </a>
        </div>
      </div>

      <div className={classes.local}>
        <img src={marker} alt="location marker" /> <br />
        <a
          href="https://www.google.com/maps/@47.2546351,19.5851122,3a,75y,214.33h,90t/data=!3m7!1e1!3m5!1sccKM_BC-KW41HezG2rTW1g!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DccKM_BC-KW41HezG2rTW1g%26cb_client%3Dmaps_sv.tactile.gps%26w%3D203%26h%3D100%26yaw%3D199.84413%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192?entry=ttu"
          target="_blank"
          rel="noopener noreferrer"
        >
          2730, Albertirsa <br />
          Madách utca
        </a>
      </div>

      <div className={classes.footer_right}>
        <p>&copy; 2023 ForDogs</p>
        <a href="mailto:fordogs@fordogs.hu">fordogs@fordogs.hu</a> <br />
        <a href="tel:+36305849227">+36305849227 </a>
        <br />
        <a
          href={hazirend}
          download={true}
          style={{ textDecoration: "underline" }}
        >
          Házirendünk itt olvashatod
        </a>
      </div>
    </footer>
  );
};

export default Footer;
