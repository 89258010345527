import { NavLink } from "react-router-dom";
import classes from "./Nav.module.css";
import React from "react";

const Nav = () => {
  const markIfActive = ({ isActive }) => (isActive ? classes.active : "");
  return (
    <div className={classes.nav}>
      <nav>
        <NavLink to={"/"} className={markIfActive}>
          Főoldal
        </NavLink>
        <NavLink to={"/kurzusok"} className={markIfActive}>
          Kurzusok
        </NavLink>
        <NavLink to={"/oktatok"} className={markIfActive}>
          Oktatók
        </NavLink>
        <NavLink to={"/helyszin"} className={markIfActive}>
          Helyszín
        </NavLink>
        <NavLink to={"/rolunk"} className={markIfActive}>
          Rólunk
        </NavLink>
        <NavLink to={"/kirandulas"} className={markIfActive}>
          Kirándulások
        </NavLink>
      </nav>
    </div>
  );
};

export default Nav;
