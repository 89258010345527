import CourseGrid from "../components/Courses/CourseGrid";
import CourseHeader from "../components/Courses/CourseHeader";
import React from "react";

const Courses = () => {
  return (
    <>
      <CourseHeader />
      <CourseGrid />
    </>
  );
};

export default Courses;
