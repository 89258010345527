import React from "react";
import banda from "../../assets/banda5.png";
import banda2 from "../../assets/banda2.png";

import hoopers from "../../assets/hoopers.jpg";
import isi1 from "../../assets/isi1.jpg";
import isi4 from "../../assets/isi4.jpg";
import isi5 from "../../assets/isi5.jpg";
import isi6 from "../../assets/isi6.jpg";
import isi7 from "../../assets/isi7.jpg";
import isi8 from "../../assets/isi8.jpg";

import classes from "./Location.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useEffect } from "react";

const Location = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.location_frame}>
      <img src={banda} alt="" className={classes.banda1} />
      <img src={banda2} alt="" className={classes.banda2} />
      <div className={classes.location_infos}>
        <h1>Helyszín</h1>
        <p>
          Sokat gondolkoztunk hol lehetne kutyiskolát nyitni, végül azért esett
          erre területre a választásunk, mert nyitott, kicsit ingergazdagabb,
          így a gazdiknak extra kihívás, hogy pórázon dolgozzon együtt a
          kutyájával, több figyelemleterelő hatással találkozhatunk, ami
          életszerűbbé teszi a gyakorlásokat.
        </p>
        <h2>Parkolás</h2>
        <p>
          Akik kocsival jönnek a helyszínre nem kell attól félniük, hogy nem
          tudják hol hagyni az autót, ugyanis a telek szélén kényelmesen van
          hely parkolni, teltház esetén pedig a telek egy részére is fel lehet
          állni.
        </p>
        <h3>Mivel várunk</h3>
        <p>
          Ha valami otthonmaradt volna, akkor se bánkódj, mert szinten mindent
          tudunk pótolni, legyen ez itatóvíz a kutyáknak, jutalomfalat,
          kakizacsi, de egészségügyi dobozzal is keszülünk baj esetére.
          Óránikontól függően pedig egyéb felszerelések is kikerülnek, legyen ez
          kúszó, kis medence, libikóka, akadály stb...
        </p>
      </div>

      <Carousel
        emulateTouch
        interval={3500}
        transitionTime={600}
        infiniteLoop
        autoPlay
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <img className={classes.carousel_img} src={isi1} />
        </div>
        <div>
          <img className={classes.carousel_img} src={hoopers} />
        </div>

        <div>
          <img
            className={classes.carousel_img}
            src={isi4}
            alt="oktatás az iskolában"
          />
        </div>
        <div>
          <img
            className={classes.carousel_img}
            src={isi5}
            alt="oktatás az iskolában"
          />
        </div>
        <div>
          <img
            className={classes.carousel_img}
            src={isi6}
            alt="oktatás az iskolában"
          />
        </div>
        <div>
          <img
            className={classes.carousel_img}
            src={isi7}
            alt="oktatás az iskolában"
          />
        </div>
        <div>
          <img
            className={classes.carousel_img}
            src={isi8}
            alt="oktatás az iskolában"
          />
        </div>
      </Carousel>
    </div>
  );
};

export default Location;
