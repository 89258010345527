import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/footer/Footer";
import NoFound from "./pages/NoFound";
import Courses from "./pages/Courses";
import Nav from "./components/nav/Nav";
import NavMobile from "./components/navMobile/NavMobile";
import CourseDetail from "./components/Courses/CourseDetail/CourseDetail";
import Teachers from "./pages/Teacher";
import AboutUs from "./pages/AboutUs";
import React from "react";
import Locations from "./pages/Locations";
import Trips from "./pages/Trips";

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <NavMobile />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/oktatok" element={<Teachers />} />
        <Route path="/kurzusok" element={<Courses />} />
        <Route path="/kurzusok/:id" element={<CourseDetail />} />
        <Route path="/rolunk" element={<AboutUs />} />
        <Route path="/helyszin" element={<Locations />} />
        <Route path="/kirandulas" element={<Trips />} />
        <Route path="*" element={<NoFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
