import React from "react";
import classes from "./Trip.module.css";

const Trips = () => {
  const TRIP_DATA = [
    {
      place: "Szentendre",
      date: "2024.02.10",
      link: "https://fb.me/e/8tM1p0Gmw",
    },
    {
      place: "Dömörkapu",
      date: "2024.03.15",
      link: "https://fb.me/e/50ZveQydL",
    },
    {
      place: "Csarna-patak",
      date: "2024.04.28",
      link: "https://fb.me/e/1Lt0GkmPQ",
    },
    {
      place: "Gaja-szurdok",
      date: "2024.06.16",
      link: "https://www.facebook.com/share/iX3GDFkuRAw9sNxU/",
    },
  ];
  return (
    // <h1 className={classes.temp_h1}>{TEMPORARY_CONTENT}</h1>
    <table className={classes.trip_table}>
      <caption>Tervezett kirándulások 2024. évben</caption>
      <caption>
        A kirándulások a táblázatban található linkkel való regisztrációhoz
        kötöttek, amely alatt az adott program rövid leírását is megtaláljátok.
        Az esetleges változtatásokat, újabb információkat a{" "}
        <a
          href="https://www.facebook.com/profile.php?id=100092629218461"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>{" "}
        oldalunkon követhetitek!
      </caption>
      <thead>
        <tr>
          <th scope="col">Helyszín</th>
          <th scope="col">Időpont</th>
          <th scope="col">Jelentkezés</th>
        </tr>
      </thead>
      <tbody>
        {TRIP_DATA.map((trip) => {
          return (
            <tr key={trip.link}>
              <td scope="row" data-label="Helyszín">
                {trip.place}
              </td>
              <td data-label="Időpont">{trip.date}</td>
              <td data-label="Jelentkezés">
                <a target="_blank" rel="noopener noreferrer" href={trip.link}>
                  Jelentkezem!
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Trips;
