import { useEffect, useState } from "react";
import classes from "./Teacher.module.css";
import React from "react";

const Teacher = ({ name, picture, bio, social }) => {
  const [biography, setBiography] = useState(bio);
  const [textIsOpened, setTextIsOpened] = useState(false);

  useEffect(() => {
    if (textIsLongerThan280Char(biography)) {
      setBiography(shortenBioTo280Char(biography));
    }
  }, []);

  const shortenBioTo280Char = (text) => {
    return text.slice(0, 280) + "...";
  };

  const textIsLongerThan280Char = (text) => {
    return text.length > 280;
  };

  const openBio = () => {
    if (textIsOpened) {
      setBiography(shortenBioTo280Char(bio));
    } else {
      setBiography(bio);
    }

    setTextIsOpened(!textIsOpened);
  };

  const showMoreBtn = textIsLongerThan280Char(biography) ? (
    <button onClick={openBio} className={classes.bio_btn}>
      {textIsOpened ? "mutass kevesebbet" : "mutass többet"}
    </button>
  ) : null;

  return (
    <div className={classes.teacher_card}>
      <div className={classes.teacher_image_caroussel}>
        <img src={picture} alt="photo about one of our teacher" />
      </div>
      <div className={classes.teacher_bio}>
        <a href={social} target="_blank" rel="noopener noreferrer">
          <h2>{name}</h2>
        </a>
        <p>{biography}</p>
        {showMoreBtn}
      </div>
    </div>
  );
};

export default Teacher;
