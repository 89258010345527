import Teacher from "./Teacher";
import classes from "./Teachers.module.css";

import adri from "../../assets/adri.jpg";
import gyorgyi from "../../assets/gyorgyi.jpg";
import oliver from "../../assets/oliver.jpg";

import bio from "./bio.json";
import { useEffect } from "react";
import React from "react";

const Teachers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.teachers_flex}>
        <Teacher
          social={bio.teacher.adri.social}
          name={bio.teacher.adri.name}
          picture={adri}
          bio={bio.teacher.adri.bio}
        />
        <Teacher
          social={bio.teacher.oliver.social}
          name={bio.teacher.oliver.name}
          picture={oliver}
          bio={bio.teacher.oliver.bio}
        />
        <Teacher
          social={bio.teacher.gyorgyi.social}
          name={bio.teacher.gyorgyi.name}
          picture={gyorgyi}
          bio={bio.teacher.gyorgyi.bio}
        />
      </div>
    </>
  );
};

export default Teachers;
