import { NavLink } from "react-router-dom";
import classes from "./NavMobile.module.css";
import { useState } from "react";
import React from "react";

const NavMobile = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const handleClick = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const menuClass = menuIsOpen ? classes.isOpen : classes.isClosed;
  return (
    <>
      <button className={classes.hamburgerBtn} onClick={handleClick}>
        <span className={`${classes.span1} ${menuClass}`}></span>
        <span className={`${classes.span2} ${menuClass}`}></span>
        <span className={`${classes.span3} ${menuClass}`}></span>
      </button>
      <nav className={classes.nav}>
        <div className={`${classes.navList} ${menuClass}`}>
          <NavLink onClick={handleClick} to={"/"} className={classes}>
            Főoldal
          </NavLink>
          <NavLink onClick={handleClick} to={"/kurzusok"} className={classes}>
            Kurzusok
          </NavLink>
          <NavLink onClick={handleClick} to={"/oktatok"} className={classes}>
            Oktatók
          </NavLink>
          <NavLink onClick={handleClick} to={"/helyszin"} className={classes}>
            Helyszín
          </NavLink>
          <NavLink onClick={handleClick} to={"/rolunk"} className={classes}>
            Rólunk
          </NavLink>
          <NavLink onClick={handleClick} to={"/kirandulas"} className={classes}>
            Kirándulások
          </NavLink>
        </div>
      </nav>
    </>
  );
};

export default NavMobile;
