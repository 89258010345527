import courseData from "./CourseData.json";
import { useParams } from "react-router-dom";
import classes from "./CourseDetail.module.css";
import facts from "../../../assets/dogschool1.jpg";
import read from "../../../assets/dogschool2.jpg";
import { useEffect } from "react";
import React from "react";

const CourseDetail = () => {
  const chosenCourse = useParams().id;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={classes.title}>
        <h1>{courseData[chosenCourse].title}</h1>
      </div>
      <div className={classes.grid_container}>
        <div className={classes.overview}>
          <h2>{courseData[chosenCourse].heading2}</h2>
          <p>{courseData[chosenCourse].overview}</p> <br />
          <p>{courseData[chosenCourse].overviewPlus}</p> <br />
          <p>{courseData[chosenCourse].overviewExtra}</p>
        </div>
        <img src={read} alt="dog reading a book" className={classes.image1} />
        <div className={classes.numbers}>
          <h3>{courseData[chosenCourse].heading3}</h3>
          <p>{courseData[chosenCourse].facts}</p>
          <br />
          <p>{courseData[chosenCourse].factsAdditional}</p> <br />
          <p>{courseData[chosenCourse].factsExtra}</p>
          <a
            href="https://forms.gle/F4RSSzjfNpj3xT2k6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>Jelentkezem!</button>
          </a>
        </div>
        <img
          src={facts}
          alt="dog chilling above book"
          className={classes.image2}
        />
      </div>
    </>
  );
};

export default CourseDetail;
