import classes from "./CourseHeader.module.css";
import React from "react";
const CourseHeader = () => {
  return (
    <header className={classes.header}>
      <h1>Iskolánkban a következő kurzusok közül tudtok választani:</h1>
    </header>
  );
};

export default CourseHeader;
