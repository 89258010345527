import classes from "./Maps.module.css";
import waveTop from "../../assets/wavesOpacity.svg";
import waveBot from "../../assets/waves.svg";
import React from "react";

export default function Maps() {
  return (
    <div>
      <img src={waveTop} alt="dividing wave" className={classes.waveTop} />
      <div className={classes.map_wrapper}>
        <iframe
          title="Fordogs kutyaiskola helyszín"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d337.1417830907911!2d19.58525999289175!3d47.25449567175699!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47419dc3bf5feff7%3A0xd43b437dad972e3c!2sFordogs%20Kutyaiskola!5e0!3m2!1sen!2shu!4v1704125855410!5m2!1sen!2shu"
        ></iframe>
      </div>
      <img src={waveBot} alt="dividing wave" className={classes.waveBot} />
    </div>
  );
}
