import classes from "./CourseGridElement.module.css";
import { useNavigate } from "react-router-dom";
import React from "react";

const CourseGridElement = ({ title, photoSrc }) => {
  const navigate = useNavigate();

  const transformTitle = () => {
    return title.slice(0).replace(/\s/g, "").toLowerCase();
  };

  const hopToDetailsPage = () => {
    navigate(transformTitle());
  };

  return (
    <div className={classes.gridCard} onClick={hopToDetailsPage}>
      <img src={photoSrc} alt="visual representation of the course" />
      <p>{title}</p>
    </div>
  );
};

export default CourseGridElement;
